import { useLocation } from 'react-router-dom';

import { useWindowSize } from '../../hooks/useWindowSize';
import { useAppContext } from '../../store/AppContext';
import { api } from '../../utils/api';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import './NavigationHeader.scss';

export default function NavigationHeader({ children }) {
  const { isMobile, isTablet } = useWindowSize();
  const { appDispatch, appState } = useAppContext();
  const { currentUser, loggedIn } = appState;

  const location = useLocation();
  const { pathname } = location;

  const isAdminPage = location.pathname === '/admin';
  const superUserMode = currentUser?.super_user && isAdminPage;

  const checkPagePath = {
    isAdminPage: isAdminPage,
    isFTUEPreviewPage: location.pathname.includes('/view-resume'),
    isResumeBuilderPage: location.pathname.includes('/resume-builder'),
    isProfileBuilderPage: location.pathname.includes('/profile-builder'),
    isPublicProfilePage: location.pathname.includes('/u/'),
  };

  const checkIsSelectedPath = (path) => path === pathname;

  const handleForceLogin = () => {
    const logout = async () => {
      try {
        await api.delete('/sessions');
        appDispatch({ type: 'currentUser/delete' });
        window.location.href = '/login';
      } catch {}
    };

    if (loggedIn) {
      logout();
    } else {
      window.location.href = '/login';
    }
  };

  return (
    <header className="__navigation-header">
      {isTablet || isMobile ? (
        <MobileHeader
          currentUser={currentUser}
          superUser={superUserMode}
          checkPagePath={checkPagePath}
          checkIsSelectedPath={checkIsSelectedPath}
          onForceLogin={handleForceLogin}
        >
          {children}
        </MobileHeader>
      ) : (
        <DesktopHeader
          currentUser={currentUser}
          superUser={superUserMode}
          checkPagePath={checkPagePath}
          onForceLogin={handleForceLogin}
        >
          {children}
        </DesktopHeader>
      )}
    </header>
  );
}
