import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import ReactFbq from 'react-fbq';
import TagManager from 'react-gtm-module';

import { RudderAnalytics } from '@rudderstack/analytics-js';

import { api } from './api';
import { envSettingTrue, getEnvSetting } from './envSettings';
import { getUserExperimentProperties } from './user';

const analytics = new RudderAnalytics();

export function parseAbTests() {
  try {
    return JSON.parse(Cookies.get('talent__ab_tests'));
  } catch {
    return {};
  }
}

export function addAbTest(identifier, value) {
  Cookies.set(
    'talent__ab_tests',
    JSON.stringify({ ...parseAbTests(), [identifier]: value }),
  );
}

export function trackEvent(event, properties, currentUser = undefined) {
  if (!TRACKING_ENABLED) {
    return;
  }

  let currentUserProperties = {};
  if (currentUser?.id) {
    currentUserProperties = getUserExperimentProperties(currentUser);
  }

  const tests = parseAbTests();
  const normalizedTests = {};

  Object.entries(tests).forEach(([key, value]) => {
    normalizedTests[`AB Test: ${key}`] = value.variant;
  });

  properties = { ...normalizedTests, ...currentUserProperties, ...properties };
  mixpanel.track(event, properties);
  /* rudderstack sdk */
  analytics.track(event, properties, () => {
    console.log('Track event successfully submitted to the RudderStack SDK.');
  });
}

export const TRACKING_ENABLED =
  process.env.NODE_ENV === 'production' || envSettingTrue('ENABLE_TRACKING');

const initialize = () => {
  if (!TRACKING_ENABLED) {
    return;
  }

  mixpanel.init(getEnvSetting('MIX_PANEL_TOKEN'), {
    debug: process.env.NODE_ENV === 'development',
  });
  ReactFbq.initialize({ id: getEnvSetting('FACEBOOK_PIXEL') });
  TagManager.initialize({ gtmId: getEnvSetting('GTM_ID') });

  /* rudderstack */

  analytics.load(
    getEnvSetting('RUDDER_STACK_WRITE_KEY'),
    getEnvSetting('RUDDER_STACK_URL'),
  );
};

const trackSignedUp = (properties) => {
  TRACKING_ENABLED && Tracking.trackUserAction('CompleteRegistration');
};

const trackPageViewed = (properties) => {
  trackEvent('page_viewed', properties);
  TRACKING_ENABLED && ReactFbq.pageView();

  // LinkedIn tracking:
  TRACKING_ENABLED && window.lintrk?.('track');
};

const identify = (unique_id) => {
  TRACKING_ENABLED && mixpanel.identify(unique_id);
  TRACKING_ENABLED && analytics.identify(unique_id);
};

const trackUserProfileData = (properties) => {
  TRACKING_ENABLED && mixpanel.people.set(properties);
  if (properties.$user_id && TRACKING_ENABLED) {
    analytics.identify(properties.$user_id, properties);
  }
};

// Track custom Facebook events
const trackCustom = (event, data) => {
  TRACKING_ENABLED && window.fbq?.('trackCustom', event, data);
};

// Brevo event tracking
const trackBrevoEvent = async (
  email,
  eventName,
  properties = {},
  contactProperties = {},
) => {
  if (!TRACKING_ENABLED) {
    return;
  }

  let payload = {
    email: email,
    event_name: eventName,
    properties: properties,
    contact_properties: contactProperties,
  };

  try {
    const response = await api.post('/track_event', payload);
    if (response.status !== 200) {
      console.error('Failed to track event', response.statusText);
    }
  } catch (error) {
    console.error('Error tracking event', error);
  }
};

// Track Time Event
const trackTimeEvent = (eventName) => {
  // TO-DO ADD RUDDERSTACK mixpanel.time_event
  TRACKING_ENABLED && mixpanel.time_event(eventName);
};

const Tracking = {
  initialize,
  identify,
  trackUserProfileData,

  // Mixpanel-only events. The number of events is kept small and generic
  trackWidgetViewed: (properties) => trackEvent('widget_viewed', properties),
  trackUserClicked: (properties, currentUser = undefined) =>
    trackEvent('user_clicked', properties, currentUser),
  trackQuestionAnswered: (properties) =>
    trackEvent('question_answered', properties),
  trackConditionMet: (properties) => trackEvent('condition_met', properties),
  reset: () => TRACKING_ENABLED && mixpanel.reset(),
  trackPageScrolled: (properties) => trackEvent('page_scrolled', properties),
  trackLatency: (properties) => trackEvent('latency_check', properties),
  trackPurchase: (properties) => trackEvent('purchase_completed', properties),
  trackLDExperiment: (properties) =>
    trackEvent('experiment_started', properties),

  // TruePath event handling using Meta API Conversions
  trackUserAction: (actionName, custom_data = {}) => {
    if (!TRACKING_ENABLED) {
      return;
    }

    custom_data.fbp = Cookies.get('_fbp');
    custom_data.fbc = Cookies.get('_fbc');
    custom_data.event_source_url = window.location.href;

    api.post('/ua', { ua: { name: actionName, custom_data } });
  },

  // Events that are used by FB Pixel, LinkedIn, and/or Mixpanel:
  trackAddToCart: () =>
    TRACKING_ENABLED && ReactFbq.track({ title: 'AddToCart' }),
  trackPageViewed,
  trackSignedUp,
  trackCustom,

  // Brevo event tracking
  trackBrevoEvent,
  trackTimeEvent,
};

export default Tracking;
