import { useContext } from 'react';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { useImmerReducer } from 'use-immer';

import Tracking, { addAbTest } from '../utils/tracking';
import { sortByDates } from '../utils/util';

const { createContext } = require('react');

const AppContext = createContext();

export const INITIAL_STREAMS = {
  resume: {},
  cover_letter: {},
  timeline: {},
};

export const DOCUMENT_BUILDER_INITIAL_STATE = {
  assetsStep: {
    view: 'SELECT_DOCUMENTS',
  },
  coverLetter: null,
  createCoverLetter: false,
  createResume: false,
  currentStep: 0,
  forNewJob: true,
  inDemandSkills: [],
  jobOpportunityDraft: null,
  jobOpportunity: null,
  resume: null,
  selectedResumeTemplate: null,
  selectedCoverLetterTemplate: null,
  reviewStep: {
    view: 'DOWNLOAD_DOCUMENTS',
  },
  selectedSkills: [],
};

export const RESUME_BUILDER_WIZARD_INITIAL_STATE = {
  builderStep: {
    view: 'JOB_DESCRIPTION',
  },
  currentStepIndex: 0,
  inProgress: false,
  firstResume: true,
  manualEntry: false,
  resume: null,
  jobOpportunityDraft: JSON.parse(localStorage.getItem('jobOpportunityDraft')),
  jobOpportunity: null,
  stepTitle: 'Assessment results',
  workHistoryStep: {
    view: 'PERSONAL_INFO',
    personalInfo: null,
    experiences: [],
    selectedExperience: null,
    educations: [],
    selectedEducation: null,
    licenses: [],
    languages: [],
    awards: [],
    publications: [],
    patents: [],
    volunteerExperiences: [],
    socials: [],
    professionalAssociations: [],
    hobbies: [],
  },
  skillsStep: {
    view: 'DREAM_JOB',
    skills: [],
    recommendedSkills: [],
  },
  resumeStep: {
    view: 'RESUME_SUMMARY',
  },
};

export const SEGMENTATION_DATA_INITIAL_STATE = {
  professionalExperienceData: {
    last_job_title: '',
    first_experience_start_date: '',
    last_experience_end_date: '',
  },
  educationData: {
    fields_of_study: '',
    degrees: '',
    last_degree: '',
    last_degree_end_date: '',
  },
};

const INITIAL_PURCHASES = () => {
  const purchases = JSON.parse(localStorage.getItem('tp__purchases'));

  if (!purchases || Array.isArray(purchases)) {
    return {};
  }

  return purchases;
};

export const APP_INITIAL_STATE = {
  currentUser: null,
  accessToken: Cookies.get('talent__api_access_token') || null,
  loggedIn: null,
  loadingLoggedInUser: false,
  abTests: {},
  importingStatus: null,
  lastSavedEvent: null,
  resumeBuilderWizard: RESUME_BUILDER_WIZARD_INITIAL_STATE,
  documentBuilder: DOCUMENT_BUILDER_INITIAL_STATE,
  showLoginModal: false,
  linkedInBio: null,
  showDashboard: false,
  isCoreData: false,
  dashboardSection: 'documents',
  purchases: INITIAL_PURCHASES(),
  purchaseFlow: null,
  hasCoreData: false,
  currentResume: null,
  currentCoverLetter: null,
  documentPreviewKey: 1,
  coreData: null,
  segmentationData: SEGMENTATION_DATA_INITIAL_STATE,
  inputResume: null,
};

export const appReducer = (draft, action) => {
  switch (action.type) {
    case 'accessToken/set':
      const accessToken = action.payload;
      Cookies.set('talent__api_access_token', accessToken, { expires: 365 });
      draft.accessToken = accessToken;
      break;

    case 'loggedIn/set':
      draft.loggedIn = action.payload;
      break;

    case 'loadingLoggedInUser/set':
      draft.loadingLoggedInUser = action.payload;
      break;

    case 'currentUser/set':
      draft.currentUser = action.payload;
      draft.loggedIn = true;
      break;

    case 'currentUser/delete':
      Cookies.remove('talent__api_access_token');
      Cookies.remove('talent__finished_survey_v2');
      Cookies.remove('talent__ab_tests');

      localStorage.removeItem('__msc_subscribed');

      draft.currentUser = null;
      draft.accessToken = null;
      draft.loggedIn = false;
      draft.resumeBuilderWizard = RESUME_BUILDER_WIZARD_INITIAL_STATE;
      draft.documentBuilder = DOCUMENT_BUILDER_INITIAL_STATE;
      draft.segmentationData = SEGMENTATION_DATA_INITIAL_STATE;

      Tracking.reset();

      break;

    case 'currentUser/setEmailVerified':
      draft.currentUser.email_verified = action.payload;
      break;

    case 'currentUser/setOnboardingStep':
      draft.currentUser.onboarding_step = action.payload;
      break;

    case 'abTest/set':
      draft.abTests[action.payload.test_identifier] = action.payload;
      addAbTest(action.payload.test_identifier, action.payload);
      break;

    case 'importingStatus/set':
      draft.importingStatus = action.payload;
      break;

    case 'showDashboard/set':
      draft.showDashboard = action.payload;
      break;

    case 'dashboardSection/set': {
      draft.dashboardSection = action.payload;
      break;
    }

    case 'lastSavedEvent/set':
      draft.lastSavedEvent = action.payload;
      break;

    case 'setTitle':
      draft.resumeBuilderWizard.stepTitle = action.payload;
      break;

    case 'purchases/add':
      const { purchaseHash, invoiceId } = action.payload;
      draft.purchases[purchaseHash] = invoiceId;
      localStorage.setItem('tp__purchases', JSON.stringify(draft.purchases));
      break;

    case 'purchaseFlow/set':
      draft.purchaseFlow = action.payload;
      break;

    case 'currentResume/set':
      draft.currentResume = action.payload;
      break;

    case 'inputResume/set':
      draft.inputResume = action.payload;
      break;

    case 'currentCoverLetter/set':
      draft.currentCoverLetter = action.payload;
      break;

    case 'resumeBuilderWizard/nextStep':
      draft.resumeBuilderWizard.currentStepIndex =
        draft.resumeBuilderWizard.currentStepIndex + 1;
      break;

    case 'resumeBuilderWizard/goToStep':
      draft.resumeBuilderWizard.currentStepIndex = action.payload.step;
      break;

    case 'resumeBuilderWizard/updateBuilderStepView':
      draft.resumeBuilderWizard.builderStep.view = action.payload.view;
      break;

    case 'resumeBuilderWizard/updateInProgress':
      draft.resumeBuilderWizard.inProgress = action.payload;
      break;

    case 'resumeBuilderWizard/setResume':
      draft.resumeBuilderWizard.resume = action.payload;
      break;

    case 'resumeBuilderWizard/setResumeData':
      const {
        contact_details_record,
        professional_experiences,
        educations,
        user_skills,
        licenses,
        spoken_languages,
        awards,
        publications,
        patents,
        volunteer_experiences,
        socials,
        professional_associations,
        objective,
        key_achievements,
        hobbies,
      } = action.payload;

      draft.resumeBuilderWizard.workHistoryStep = {
        view: draft.resumeBuilderWizard.workHistoryStep.view,
        personalInfo: {
          id: contact_details_record?.id,
          first_name: contact_details_record?.first_name || '',
          last_name: contact_details_record?.last_name || '',
          email:
            contact_details_record?.email ||
            (draft.currentUser?.registered && draft.currentUser?.email) ||
            '',
          address: contact_details_record?.address || '',
          city: contact_details_record?.city || '',
          state: contact_details_record?.state || '',
          zip: contact_details_record?.zip || '',
          phone: contact_details_record?.phone || '',
          pronouns: contact_details_record?.pronouns || '',
          relocate: contact_details_record?.relocate || false,
          honorific: contact_details_record?.honorific || '',
          job_title: contact_details_record?.job_title || '',
          prefix: contact_details_record?.prefix || '',
        },
        experiences: professional_experiences || [],
        selectedExperience: null,
        educations: educations || [],
        selectedEducation: null,
        licenses: licenses || [],
        languages: spoken_languages || [],
        awards: awards || [],
        publications: publications || [],
        patents: patents || [],
        volunteerExperiences: volunteer_experiences || [],
        socials: socials || [],
        professionalAssociations: professional_associations || [],
        objective: objective || {},
        keyAchievements: key_achievements || [],
        hobbies: hobbies || [],
      };

      draft.resumeBuilderWizard.skillsStep = {
        view: draft.resumeBuilderWizard.skillsStep.view,
        skills: user_skills || [],
        recommendedSkills:
          draft.resumeBuilderWizard.skillsStep.recommendedSkills || [],
      };
      break;

    case 'resumeBuilderWizard/resetResumeData':
      const nameParts = draft.currentUser?.user_name?.split(' ');

      draft.resumeBuilderWizard.inProgress =
        action?.payload?.inProgress || false;
      draft.resumeBuilderWizard.manualEntry =
        action?.payload?.manualEntry || false;

      draft.resumeBuilderWizard.workHistoryStep = {
        view: action.payload.view,
        personalInfo: {
          first_name: nameParts?.[0] || '',
          last_name: nameParts?.[1] || '',
          email: draft.currentUser?.email,
          address: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          pronouns: '',
          relocate: false,
          honorific: '',
          job_title: '',
          prefix: '',
        },
        experiences: [],
        selectedExperience: null,
        educations: [],
        selectedEducation: null,
        licenses: [],
        languages: [],
        awards: [],
        publications: [],
        patents: [],
        volunteerExperiences: [],
        socials: [],
        professionalAssociations: [],
        objective: {},
        key_achievements: [],
        hobbies: [],
      };

      draft.resumeBuilderWizard.skillsStep = {
        view: 'DREAM_JOB',
        skills: [],
        recommendedSkills:
          draft.resumeBuilderWizard.skillsStep.recommendedSkills || [],
      };

      draft.jobOpportunityDraft = null;

      break;

    case 'resumeBuilderWizard/updateWorkHistoryView':
      draft.resumeBuilderWizard.workHistoryStep.view = action.payload;
      break;

    case 'resumeBuilderWizard/updateSkillsView':
      draft.resumeBuilderWizard.skillsStep.view = action.payload;
      break;

    case 'resumeBuilderWizard/previousStep':
      draft.resumeBuilderWizard.currentStepIndex =
        draft.resumeBuilderWizard.currentStepIndex - 1;
      break;

    case 'resumeBuilderWizard/setStep':
      draft.resumeBuilderWizard.currentStepIndex = action.payload;
      break;

    case 'resumeBuilderWizard/updateWorkHistoryPersonalInfo':
      const personalInfo = action.payload || {};
      const parts = draft.currentUser?.user_name?.split(' ');

      draft.resumeBuilderWizard.workHistoryStep.personalInfo = {
        ...personalInfo,
      };

      if (!personalInfo.first_name) {
        draft.resumeBuilderWizard.workHistoryStep.personalInfo.first_name =
          parts?.[0] || '';
      }

      if (!personalInfo.last_name) {
        draft.resumeBuilderWizard.workHistoryStep.personalInfo.last_name =
          parts?.[1] || '';
      }

      if (!personalInfo.email) {
        draft.resumeBuilderWizard.workHistoryStep.personalInfo.email =
          draft.currentUser?.email || '';
      }

      break;

    case 'resumeBuilderWizard/updateWorkHistoryExperiences': {
      draft.resumeBuilderWizard.workHistoryStep.experiences =
        action.payload.professionalExperiences || [];
      break;
    }

    case 'resumeBuilderWizard/updateResumeObjective': {
      draft.resumeBuilderWizard.workHistoryStep.objective = action.payload;
      break;
    }

    case 'resumeBuilderWizard/updateResumeKeyAchievements': {
      draft.resumeBuilderWizard.workHistoryStep.keyAchievements =
        action.payload;
      break;
    }

    case 'resumeBuilderWizard/setJobOpportunityDraft':
      draft.resumeBuilderWizard.jobOpportunityDraft = action.payload;
      break;

    case 'resumeBuilderWizard/setJobOpportunity':
      draft.resumeBuilderWizard.jobOpportunity = action.payload;
      break;

    case 'resumeBuilderWizard/setSelectedExperience':
      draft.resumeBuilderWizard.workHistoryStep.selectedExperience =
        action.payload;
      break;

    case 'resumeBuilderWizard/setSelectedEducation':
      draft.resumeBuilderWizard.workHistoryStep.selectedEducation =
        action.payload;
      break;

    case 'resumeBuilderWizard/updateWorkHistoryExperience': {
      const experiences =
        draft.resumeBuilderWizard.workHistoryStep.experiences || [];
      const experience = action.payload;
      draft.resumeBuilderWizard.workHistoryStep.selectedExperience = experience;

      if (Object.keys(experience).length > 0) {
        const index = experiences.findIndex(
          (item) => item.id === experience.id,
        );
        if (index >= 0) {
          draft.resumeBuilderWizard.workHistoryStep.experiences[index] =
            experience;
        } else {
          draft.resumeBuilderWizard.workHistoryStep.experiences.push(
            experience,
          );
        }
      }

      // Order experiences
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.experiences.slice();
      sortedArray.sort(sortByDates);
      draft.resumeBuilderWizard.workHistoryStep.experiences = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteWorkHistoryExperience': {
      const experiences = draft.resumeBuilderWizard.workHistoryStep.experiences;
      const experience = action.payload;
      const index = experiences.findIndex((item) => item.id === experience.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.experiences.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateEducations': {
      draft.resumeBuilderWizard.workHistoryStep.educations =
        action.payload.educations || [];
      break;
    }

    case 'resumeBuilderWizard/updateEducation': {
      const educations =
        draft.resumeBuilderWizard.workHistoryStep.educations || [];
      const education = action.payload;
      draft.resumeBuilderWizard.workHistoryStep.selectedEducation = education;

      if (Object.keys(education).length > 0) {
        const index = educations.findIndex((item) => item.id === education.id);

        if (index >= 0) {
          draft.resumeBuilderWizard.workHistoryStep.educations[index] =
            education;
        } else {
          draft.resumeBuilderWizard.workHistoryStep.educations.push(education);
        }
      }

      // Order educations
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.educations.slice();
      sortedArray.sort(sortByDates);
      draft.resumeBuilderWizard.workHistoryStep.educations = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteEducation': {
      const educations = draft.resumeBuilderWizard.workHistoryStep.educations;
      const education = action.payload;
      const index = educations.findIndex((item) => item.id === education.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.educations.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateSkills': {
      const skills = action.payload || [];
      const selectedSkills = skills.slice().sort((a, b) => a.id - b.id);
      draft.resumeBuilderWizard.skillsStep.skills = selectedSkills;
      break;
    }

    case 'resumeBuilderWizard/updateImportedSkill': {
      const skill = action.payload;
      const skills = draft.resumeBuilderWizard.skillsStep.skills;
      draft.resumeBuilderWizard.skillsStep.skills = skills.map((s) => {
        if (s.id === skill.id) {
          return { ...s, skill_added: skill.skill_added };
        }
        return s;
      });
      break;
    }

    case 'resumeBuilderWizard/deleteSkill': {
      const skills = draft.resumeBuilderWizard.skillsStep.skills;
      draft.resumeBuilderWizard.skillsStep.skills = skills.filter(
        (skill) => skill.id !== action.payload,
      );
      break;
    }

    case 'resumeBuilderWizard/setInDemandSkills': {
      draft.resumeBuilderWizard.skillsStep.recommendedSkills =
        action.payload || [];
      break;
    }

    case 'resumeBuilderWizard/updateLicense': {
      const licenses = draft.resumeBuilderWizard.workHistoryStep.licenses || [];
      const license = action.payload;
      const index = licenses.findIndex((item) => item.id === license.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.licenses[index] = license;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.licenses.push(license);
      }

      // Order licenses
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.licenses.slice();
      sortedArray.sort((a, b) => {
        const time_1 = a.issued_date
          ? DateTime.fromFormat(a.issued_date, 'yyyy-MM-dd')
          : null;
        const time_2 = b.issued_date
          ? DateTime.fromFormat(b.issued_date, 'yyyy-MM-dd')
          : null;

        return time_1 < time_2 ? 1 : -1;
      });
      draft.resumeBuilderWizard.workHistoryStep.licenses = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteLicense': {
      const licenses = draft.resumeBuilderWizard.workHistoryStep.licenses;
      const license = action.payload;
      const index = licenses.findIndex((item) => item.id === license.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.licenses.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateLanguage': {
      const languages =
        draft.resumeBuilderWizard.workHistoryStep.languages || [];
      const language = action.payload;
      const index = languages.findIndex((item) => item.id === language.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.languages[index] = language;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.languages.push(language);
      }

      // Order languages
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.languages.slice();
      sortedArray.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      draft.resumeBuilderWizard.workHistoryStep.languages = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteLanguage': {
      const languages = draft.resumeBuilderWizard.workHistoryStep.languages;
      const language = action.payload;
      const index = languages.findIndex((item) => item.id === language.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.languages.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateAward': {
      const awards = draft.resumeBuilderWizard.workHistoryStep.awards || [];
      const award = action.payload;
      const index = awards.findIndex((item) => item.id === award.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.awards[index] = award;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.awards.push(award);
      }

      // Order awards
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.awards.slice();
      sortedArray.sort((a, b) => {
        const time_1 = a.issued_date
          ? DateTime.fromFormat(a.issued_date, 'yyyy-MM-dd')
          : null;
        const time_2 = b.issued_date
          ? DateTime.fromFormat(b.issued_date, 'yyyy-MM-dd')
          : null;

        return time_1 < time_2 ? 1 : -1;
      });
      draft.resumeBuilderWizard.workHistoryStep.awards = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteAward': {
      const awards = draft.resumeBuilderWizard.workHistoryStep.awards;
      const award = action.payload;
      const index = awards.findIndex((item) => item.id === award.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.awards.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updatePublication': {
      const publications =
        draft.resumeBuilderWizard.workHistoryStep.publications || [];
      const publication = action.payload;
      const index = publications.findIndex(
        (item) => item.id === publication.id,
      );
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.publications[index] =
          publication;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.publications.push(
          publication,
        );
      }

      // Order publications
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.publications.slice();
      sortedArray.sort((a, b) => {
        const time_1 = a.publication_date
          ? DateTime.fromFormat(a.publication_date, 'yyyy-MM-dd')
          : null;
        const time_2 = b.publication_date
          ? DateTime.fromFormat(b.publication_date, 'yyyy-MM-dd')
          : null;

        return time_1 < time_2 ? 1 : -1;
      });
      draft.resumeBuilderWizard.workHistoryStep.publications = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deletePublication': {
      const publications =
        draft.resumeBuilderWizard.workHistoryStep.publications;
      const publication = action.payload;
      const index = publications.findIndex(
        (item) => item.id === publication.id,
      );

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.publications.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updatePatent': {
      const patents = draft.resumeBuilderWizard.workHistoryStep.patents || [];
      const patent = action.payload;
      const index = patents.findIndex((item) => item.id === patent.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.patents[index] = patent;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.patents.push(patent);
      }

      // Order Patents
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.patents.slice();
      sortedArray.sort((a, b) => {
        const time_1 = a.file_date
          ? DateTime.fromFormat(a.file_date, 'yyyy-MM-dd')
          : null;
        const time_2 = b.file_date
          ? DateTime.fromFormat(b.file_date, 'yyyy-MM-dd')
          : null;

        return time_1 < time_2 ? 1 : -1;
      });
      draft.resumeBuilderWizard.workHistoryStep.patents = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deletePatent': {
      const patents = draft.resumeBuilderWizard.workHistoryStep.patents;
      const patent = action.payload;
      const index = patents.findIndex((item) => item.id === patent.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.patents.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateVolunteerExperience': {
      const volunteerExperiences =
        draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences || [];
      const experience = action.payload;
      const index = volunteerExperiences.findIndex(
        (item) => item.id === experience.id,
      );

      if (Object.keys(experience).length > 0) {
        if (index >= 0) {
          draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences[
            index
          ] = experience;
        } else {
          draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences.push(
            experience,
          );
        }
      }

      // Order volunteer experiences
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences.slice();
      sortedArray.sort(sortByDates);
      draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences =
        sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteVolunteerExperience': {
      const volunteerExperiences =
        draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences;
      const experience = action.payload;
      const index = volunteerExperiences.findIndex(
        (item) => item.id === experience.id,
      );

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.volunteerExperiences.splice(
          index,
          1,
        );
      }
      break;
    }

    case 'resumeBuilderWizard/updateSocial': {
      const socials = draft.resumeBuilderWizard.workHistoryStep.socials || [];
      const social = action.payload;
      const index = socials.findIndex((item) => item.id === social.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.socials[index] = social;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.socials.push(social);
      }

      // Order socials
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.socials.slice();
      sortedArray.sort((a, b) => {
        const labelA = a.label?.toLowerCase();
        const labelB = b.label?.toLowerCase();

        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });

      draft.resumeBuilderWizard.workHistoryStep.socials = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteSocial': {
      const socials = draft.resumeBuilderWizard.workHistoryStep.socials;
      const social = action.payload;
      const index = socials.findIndex((item) => item.id === social.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.socials.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateProfessionalAssociation': {
      const professionalAssociations =
        draft.resumeBuilderWizard.workHistoryStep.professionalAssociations ||
        [];
      const association = action.payload;
      const index = professionalAssociations.findIndex(
        (item) => item.id === association.id,
      );

      if (Object.keys(association).length > 0) {
        if (index >= 0) {
          draft.resumeBuilderWizard.workHistoryStep.professionalAssociations[
            index
          ] = association;
        } else {
          draft.resumeBuilderWizard.workHistoryStep.professionalAssociations.push(
            association,
          );
        }
      }

      // Order professional associations
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.professionalAssociations.slice();
      sortedArray.sort(sortByDates);
      draft.resumeBuilderWizard.workHistoryStep.professionalAssociations =
        sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteProfessionalAssociation': {
      const professionalAssociations =
        draft.resumeBuilderWizard.workHistoryStep.professionalAssociations;
      const association = action.payload;
      const index = professionalAssociations.findIndex(
        (item) => item.id === association.id,
      );

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.professionalAssociations.splice(
          index,
          1,
        );
      }
      break;
    }

    case 'resumeBuilderWizard/updateHobby': {
      const hobbies = draft.resumeBuilderWizard.workHistoryStep.hobbies || [];
      const hobby = action.payload;
      const index = hobbies.findIndex((item) => item.id === hobby.id);
      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.hobbies[index] = hobby;
      } else {
        draft.resumeBuilderWizard.workHistoryStep.hobbies.push(hobby);
      }

      // Order hobbies
      const sortedArray =
        draft.resumeBuilderWizard.workHistoryStep.hobbies.slice();
      sortedArray.sort((a, b) => {
        const nameA = a.name?.toLowerCase();
        const nameB = b.name?.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      draft.resumeBuilderWizard.workHistoryStep.hobbies = sortedArray;
      break;
    }

    case 'resumeBuilderWizard/deleteHobby': {
      const hobbies = draft.resumeBuilderWizard.workHistoryStep.hobbies;
      const hobby = action.payload;
      const index = hobbies.findIndex((item) => item.id === hobby.id);

      if (index >= 0) {
        draft.resumeBuilderWizard.workHistoryStep.hobbies.splice(index, 1);
      }
      break;
    }

    case 'resumeBuilderWizard/updateResumeStepView': {
      draft.resumeBuilderWizard.resumeStep.view = action.payload.view;
      break;
    }

    case 'documentPreview/set': {
      draft.documentPreviewKey += 1;
      break;
    }

    case 'linkedInBio/set':
      draft.linkedInBio = action.payload;
      break;

    case 'loginModal/set': {
      draft.showLoginModal = action.payload;
      break;
    }

    case 'documentBuilder/resetData':
      draft.documentBuilder = DOCUMENT_BUILDER_INITIAL_STATE;
      break;

    case 'documentBuilder/goToStep':
      draft.documentBuilder.currentStep = action.payload.step;
      break;

    case 'documentBuilder/nextStep':
      draft.documentBuilder.currentStep = draft.documentBuilder.currentStep + 1;
      break;

    case 'documentBuilder/previousStep':
      draft.documentBuilder.currentStep = draft.documentBuilder.currentStep - 1;
      break;

    case 'documentBuilder/setDocumentsToCreate':
      draft.documentBuilder.createResume = action.payload.createResume;
      draft.documentBuilder.createCoverLetter =
        action.payload.createCoverLetter;
      break;

    case 'documentBuilder/updateAssetsStepView':
      draft.documentBuilder.assetsStep.view = action.payload;
      break;

    case 'documentBuilder/updateReviewStepView':
      draft.documentBuilder.reviewStep.view = action.payload;
      break;

    case 'documentBuilder/setForNewJob':
      draft.documentBuilder.forNewJob = action.payload;
      break;

    case 'documentBuilder/setCoverLetter':
      draft.documentBuilder.coverLetter = action.payload;
      break;

    case 'documentBuilder/setJobOpportunityDraft':
      draft.documentBuilder.jobOpportunityDraft = action.payload;
      draft.documentBuilder.inDemandSkills = [];
      break;

    case 'documentBuilder/setJobOpportunity':
      draft.documentBuilder.jobOpportunity = action.payload;
      draft.documentBuilder.inDemandSkills = [];
      break;

    case 'documentBuilder/setResume':
      draft.documentBuilder.resume = action.payload;
      break;

    case 'documentBuilder/setInDemandSkills': {
      draft.documentBuilder.inDemandSkills = action.payload || [];
      break;
    }

    case 'documentBuilder/updateSelectedSkills':
      draft.documentBuilder.selectedSkills = action.payload || [];
      break;

    case 'documentBuilder/setResumeTemplate':
      draft.documentBuilder.selectedResumeTemplate = action.payload;
      break;

    case 'documentBuilder/setCoverLetterTemplate':
      draft.documentBuilder.selectedCoverLetterTemplate = action.payload;
      break;

    case 'coreData/set':
      draft.coreData = action.payload;
      break;

    case 'isCoreData/set':
      draft.isCoreData = action.payload;
      break;

    case 'currentUser/updateDefaultResumeTemplate':
      draft.currentUser.resume_template_id = action.payload;
      break;

    case 'currentUser/updateDefaultCoverLetterTemplate':
      draft.currentUser.cover_letter_template_id = action.payload;
      break;

    case 'currentUser/updateDefaultCoverLetterColor':
      draft.currentUser.cover_letter_color = action.payload;
      break;

    case 'currentUser/updateDefaultResumeColor':
      draft.currentUser.resume_color = action.payload;
      break;

    case 'hasCoreData/set':
      draft.hasCoreData = action.payload;
      break;

    case 'segmentationData/resetData':
      draft.segmentationData = SEGMENTATION_DATA_INITIAL_STATE;
      break;

    case 'segmentationData/setProfessionalExperienceData':
      draft.segmentationData.professionalExperienceData = action.payload;
      break;

    case 'segmentationData/setEducationData':
      draft.segmentationData.educationData = action.payload;
      break;

    default:
      console.warn('Non-existing action type:', action.type);
  }
};

export default AppContext;

export function AppContextProvider({ children }) {
  const appContext = useImmerReducer(appReducer, APP_INITIAL_STATE);

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
}

// Custom hook to use the ResumeBuilderContext
export function useAppContext() {
  const [appState, appDispatch] = useContext(AppContext);
  return { appState, appDispatch };
}
