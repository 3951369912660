export const PLANS_FTUE = [
  {
    id: 10,
    internal_id: 1,
    free_trial: true,
    title: '7-day free trial',
    statName: 'free trial',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$0',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/now',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 10,
    internal_id: 2,
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '7-day free trial, then pay $59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 6 months`,
    type: 'membership',
    amount: 5940,
  },
];

export const PLANS_UNSUSCRIBED = [
  {
    id: 3,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
  },
  {
    id: 10,
    internal_id: 2,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '$24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '$59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '6 month commitment at 60% off.',
    disclaimer: `You will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 6 months`,
    type: 'membership',
    amount: 5940,
  },
];

export const PLANS_PAYWALL_MARKETING_CAMPAIGN = [
  {
    id: 10,
    internal_id: 2,
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7-day free trial, then pay $24.90 every month, cancel anytime.',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle:
      '7-day free trial, then pay $59.40 every 6 months, cancel anytime.',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 6 months`,
    type: 'membership',
    tag: '60% OFF',
    amount: 5940,
  },
];

export const PLANS_UNSUSCRIBED_DISCOUNTS = [
  {
    id: 10,
    internal_id: 2,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: 'for first month, then $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    type: 'membership',
    amount: 2490,
  },
];

export const PLANS_PRICING_EXPERIMENT = [
  {
    id: 10,
    internal_id: 1,
    tier: '1-month',
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7 days free, then $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    transparentTag: '7-day free trial',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 14,
    tier: '3-month',
    transparentTag: '7-day free trial',
    internal_id: 2,
    free_trial: true,
    title: '3 months',
    statName: '3 month unlimited',
    subTitle: '7 days free, then $59.70 every 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every 3 months. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a 3-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.70, charged every 3 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 3 months`,
    type: 'membership',
    amount: 5970,
  },
  {
    id: 12,
    tier: '6-month',
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    transparentTag: '7-day free trial',
    statName: '6 month unlimited',
    subTitle: '7 days free, then $59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Trudy 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 6 months`,
    type: 'membership',
    amount: 5940,
  },
  {
    id: 3,
    internal_id: 0,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
    oneTimePayment: true,
  },
];
export const PLANS_PRICING_EXPERIMENT_UNSUBSCRIBED = [
  {
    id: 10,
    internal_id: 1,
    tier: '1-month',
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '$24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 1 month`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 14,
    tier: '3-month',
    internal_id: 2,
    title: '3 months',
    statName: '3 month unlimited',
    subTitle: '$59.70 every 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $13.90, charged every 3 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 3 months`,
    type: 'membership',
    amount: 5970,
  },
  {
    id: 12,
    tier: '6-month',
    internal_id: 3,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '$59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    autoRenewMessage: (date, discountedPrice) =>
      `Auto-renews on ${date} at ${discountedPrice} for 6 months`,
    type: 'membership',
    amount: 5940,
  },
  {
    id: 3,
    internal_id: 0,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
    oneTimePayment: true,
  },
];

export const MONTHLY_TIERS = {
  '1-month': {
    id: 10,
    title: 'Unlimited – 1 month',
    price: '$24.90',
    fullPrice: '$24.90',
    commitment: '1 month commitment',
    mostPopular: false,
    trialScreenCopy: '7 days free, then $24.90/mo',
    trialScreenDisclaimer: '(Charged monthly)',
    months: 1,
  },
  '3-month': {
    id: 14,
    title: 'Unlimited – 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    commitment: '3 months commitment',
    mostPopular: true,
    trialScreenCopy: '7 days free, then $19.90/mo',
    trialScreenDisclaimer: '($59.70 charged every 3 months)',
    months: 3,
  },
  '6-month': {
    id: 12,
    price: '$9.90',
    title: 'Unlimited – 6 months',
    fullPrice: '$59.40',
    commitment: '6 months commitment',
    mostPopular: false,
    trialScreenCopy: '7 days free, then $9.90/mo',
    trialScreenDisclaimer: '($59.40 charged every 6 months)',
    months: 6,
  },
  'pay-as-you-go': {
    id: 3,
    title: 'Pay as you go',
    price: '$4.90',
    fullPrice: '$4.90',
    onePayment: true,
    months: 0,
  },
};

export const COUNTRIES = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AX', name: 'Åland Islands' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AG', name: 'Antigua & Barbuda' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AC', name: 'Ascension Island' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BA', name: 'Bosnia & Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BR', name: 'Brazil' },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'VG', name: 'British Virgin Islands' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CA', name: 'Canada' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'BQ', name: 'Caribbean Netherlands' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros' },
  { code: 'CG', name: 'Congo - Brazzaville' },
  { code: 'CD', name: 'Congo - Kinshasa' },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CI', name: 'Côte d’Ivoire' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czechia' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estonia' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FK', name: 'Falkland Islands' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'TF', name: 'French Southern Territories' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong SAR China' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'XK', name: 'Kosovo' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: 'Laos' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MO', name: 'Macao SAR China' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Mexico' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar (Burma)' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PS', name: 'Palestinian Territories' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PN', name: 'Pitcairn Islands' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russia' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'São Tomé & Príncipe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SX', name: 'Sint Maarten' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'GS', name: 'South Georgia & South Sandwich Islands' },
  { code: 'KR', name: 'South Korea' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'BL', name: 'St. Barthélemy' },
  { code: 'SH', name: 'St. Helena' },
  { code: 'KN', name: 'St. Kitts & Nevis' },
  { code: 'LC', name: 'St. Lucia' },
  { code: 'MF', name: 'St. Martin' },
  { code: 'PM', name: 'St. Pierre & Miquelon' },
  { code: 'VC', name: 'St. Vincent & Grenadines' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SJ', name: 'Svalbard & Jan Mayen' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad & Tobago' },
  { code: 'TA', name: 'Tristan da Cunha' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TR', name: 'Türkiye' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks & Caicos Islands' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VA', name: 'Vatican City' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'WF', name: 'Wallis & Futuna' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'YE', name: 'Yemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
];

export const DISCOUNT_COUPONS = {
  Kw18xMIc: {
    id: 'Kw18xMIc',
    percent_off: 30.0,
    duration: 'forever',
  },
  mpkOuFQd: {
    id: 'mpkOuFQd',
    percent_off: 30.0,
    duration: 'forever',
  },
};
