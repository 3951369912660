import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import KoiToast from '../../common/KoiToast/KoiToast';
import { CustomConversionEvents } from '../../constants/featureFlags';
import {
  FREE_MONTH_SPECIAL,
  PAYMENT_ERROR,
  PLAN_FEATURES,
  REFERRAL_BONUS,
} from '../../constants/payment';
import useGTM from '../../hooks/useGTM';
import useLaunchPaywallModal from '../../hooks/useLaunchPaywallModal';
import usePaywallDiscountModal from '../../hooks/usePaywallDiscountModal';
import usePaywallMonthDiscount from '../../hooks/usePaywallMonthDiscount';
import { useAppContext } from '../../store/AppContext';
import { api } from '../../utils/api';
import { ENV_TEST, getEnvSetting } from '../../utils/envSettings';
import Tracking from '../../utils/tracking';
import { updateLatestUserData } from '../../utils/user';

import { AccountCreation, SignUpDescription } from './AccountScreen';
import { DISCOUNT_COUPONS } from './constants';
import FreeTrialScreen from './FreeTrialScreen';
import { Payment, SelectedPlanDetails } from './PaymentScreen';
import {
  DEFAULT_TARGET_PLAN,
  getCurrentPlansList,
  getPlan,
  getPlansDiscountModalCheck,
  getProductSpecs,
  INITIAL_DATA,
  trackTrialReminder,
} from './PaywallHelpers';
import {
  createInvoice,
  createSubscription,
  updateInvoice,
  updateSubscription,
} from './PaywallServices';
import { Description, PlanSelection } from './PlansScreen';
import SuccessfulPurchase from './SuccessfulPurchase';

import './PaywallV2.scss';

const MOCK_PAYMENT = false;
const reCaptchaSiteKey = getEnvSetting('RECAPTCHA_SITE_KEY');

const PaywallV2 = ({
  onHide,
  ftue = false,
  initialScreen,
  showPromoCode = true,
}) => {
  /* COMPONENT LOGIC STATES */
  const [error, setError] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [formData, setFormData] = useState(INITIAL_DATA);

  /* DISCOUNTS */
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeStatus, setPromoCodeStatus] = useState('');
  const [coupon, setCoupon] = useState(false);
  const couponRef = useRef(coupon);

  /* CUSTOM HOOKS */
  const ldClient = useLDClient();
  const recaptchaRef = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const [params] = useSearchParams();
  const { trackGTMEvent } = useGTM();
  const { usePaywallMarketingCampaign } = useLaunchPaywallModal();
  const { appDispatch: dispatch, appState: state } = useAppContext();
  const { utmDiscount, utmStripeId, showPaywallDiscountModal } =
    usePaywallDiscountModal();
  const {
    utmContent,
    utmStripeId: utmStripeIdP,
    showPaywallMonthDiscount,
  } = usePaywallMonthDiscount();

  /* LOCAL VARS */
  const { currentUser } = state;
  const isUnsubscribed = currentUser?.unsubscribed;
  const isSubscribed = currentUser?.subscribed;
  const target = params.get('plan')
    ? Number(params.get('plan'))
    : DEFAULT_TARGET_PLAN;

  let PLANS_TO_USE = getCurrentPlansList({
    usePaywallMarketingCampaign,
    isUnsubscribed,
  });

  /* Only check plans under discount modal flag */
  PLANS_TO_USE = getPlansDiscountModalCheck(
    isUnsubscribed,
    showPaywallDiscountModal,
    showPaywallMonthDiscount,
    utmDiscount,
    PLANS_TO_USE,
    isSubscribed,
  );

  /* SCREEN MANAGMENT */
  const plan = getPlan(currentUser, PLANS_TO_USE, target);
  const shouldShowInfoScreen =
    plan?.internal_id !== 0 || window.location.pathname.includes('view-resume');
  const shouldShowInfoScreenFullValidation =
    shouldShowInfoScreen && !isUnsubscribed && !showPaywallMonthDiscount;
  const [selectedPlan, setSelectedPlan] = useState(plan || PLANS_TO_USE[0]);
  const [showPricingExperimentScreen, setShowPricingExperimentScreen] =
    useState(shouldShowInfoScreenFullValidation);
  const [currentScreen, setCurrentScreen] = useState(initialScreen || 'first');
  const subscriptionType = selectedPlan?.type === 'membership';
  const productSpecs = getProductSpecs();

  /*  DISCOUNT LOGIC Start */
  useEffect(() => {
    if (showPaywallDiscountModal) {
      setCoupon({
        promoCode: `[Special Offer] [Discount ${utmDiscount}%]`,
        percent_off: utmDiscount,
        id: utmStripeId,
      });
    }
  }, [showPaywallDiscountModal, utmDiscount, utmStripeId]);

  useEffect(() => {
    if (
      showPaywallMonthDiscount &&
      utmContent.toUpperCase() === FREE_MONTH_SPECIAL.promoCode
    ) {
      setCoupon({
        promoCode: FREE_MONTH_SPECIAL.promoCode,
        percent_off: FREE_MONTH_SPECIAL.percentOff,
        id: utmStripeIdP,
      });
    }
  }, [showPaywallMonthDiscount, utmContent, utmStripeIdP]);

  useEffect(() => {
    couponRef.current = coupon;
  }, [coupon]);

  useEffect(() => {
    const subscriptionCoupon = currentUser.subscription?.coupon;
    const discountCoupon = DISCOUNT_COUPONS[subscriptionCoupon];
    if (discountCoupon) {
      setCoupon(discountCoupon);
    }
  }, [currentUser.subscription]);

  const handleFormChange = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  /*  DISCOUNT LOGIC End */

  /* PAYWALL LOGIC Start*/
  const handleUpdateLatestUserData = useCallback(() => {
    updateLatestUserData(dispatch);
  }, [dispatch]);

  const onPaymentSuccessful = useCallback(async () => {
    const { data } = await api.get('/users/me');
    await dispatch({ type: 'currentUser/set', payload: data });

    setPurchaseSuccess(true);
    setProcessingPayment(false);
  }, [dispatch]);

  useEffect(() => {
    handleUpdateLatestUserData();
  }, [handleUpdateLatestUserData]);

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);

    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: plan.subTitle,
          amount: plan.amount,
        },
      });
    }
  };

  // After a successful free trial, invoice, or subscription, follow the succesful payment flow:
  const successfulPaymentFlow = useCallback(
    async (invoiceId) => {
      const gross_value = parseFloat(
        selectedPlan.fullPrice.replace(/[^0-9.-]+/g, ''),
      );

      const value = coupon
        ? (((100 - coupon.percent_off) / 100) * gross_value).toFixed(2)
        : gross_value;

      Tracking.trackUserAction('Purchase', {
        value,
        currency: 'USD',
        fbp: Cookies.get('_fbp'),
        fbc: Cookies.get('_fbc'),
      });
      const safeValue = value || 29.0;

      const additionalParams = {
        from_view: 'Paywall',
        value: safeValue,
        promo_code: coupon?.promoCode?.toLowerCase() || '',
        plan_id: selectedPlan.id,
        currency: 'USD',
        transaction_id: invoiceId || '',
      };

      Tracking.trackPurchase({
        ...additionalParams,
        value: selectedPlan.free_trial ? 0 : safeValue,
      });

      additionalParams.currentUser = currentUser;
      trackGTMEvent('completePurchase', additionalParams);

      ldClient.track(CustomConversionEvents.COMPLETE_PURCHASE, {
        plan: selectedPlan.title,
      });

      if (coupon?.promoCode === REFERRAL_BONUS) {
        Tracking.trackConditionMet({
          widget_name: 'Redeem Coupon Code',
          context: 'Referrals',
        });
      }

      if (coupon?.promoCode === FREE_MONTH_SPECIAL.promoCode) {
        Tracking.trackConditionMet({
          widget_name: 'Redeem 1-Month off Coupon Code',
          context: 'Marketing',
        });
      }
      onPaymentSuccessful();
      setProcessingPayment(false);
    },
    [
      selectedPlan,
      coupon,
      currentUser,
      onPaymentSuccessful,
      trackGTMEvent,
      ldClient,
    ],
  );

  useEffect(() => {
    if (!stripe || !elements || paymentRequest) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: selectedPlan.subTitle,
        amount: selectedPlan.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      try {
        await api.post('/payments/payment_methods', {
          stripe_id: e.paymentMethod.id,
        });

        if (selectedPlan.type === 'membership') {
          const subscription = await createSubscription(
            selectedPlan.id,
            selectedPlan.free_trial,
            true,
            couponRef,
            recaptchaRef,
            setSubscriptionInfo,
            setError,
            setProcessingPayment,
          );

          e.complete('success');
          return successfulPaymentFlow(subscription.stripe_id);
        } else {
          // Create before a Stripe payment
          const invoice = await createInvoice(true, selectedPlan, couponRef);
          e.complete('success');
          return successfulPaymentFlow(invoice.stripe_id);
        }
      } catch (error) {
        e.complete('fail');
        setProcessingPayment(false);
        setError(PAYMENT_ERROR);
        return;
      }
    });
  }, [stripe, elements, selectedPlan, successfulPaymentFlow, paymentRequest]);

  const hanldeUpdatePayment = async (event) => {
    event.preventDefault();
    setError(null);
    setValidated(true);

    const form = event.currentTarget;
    const isValid = form.checkValidity();
    if (!isValid) {
      return;
    }

    if (MOCK_PAYMENT) {
      return onPaymentSuccessful();
    }

    // Stripe.js has not loaded yet.
    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement
    const cardElement = elements.getElement(CardNumberElement);
    try {
      setProcessingPayment(true);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: get(formData, 'name', currentUser.user_name),
          email: currentUser.email,
          address: {
            postal_code: get(formData, 'zipCode', ''),
            country: get(formData, 'country', 'US'),
          },
        },
      });

      const payload = {
        stripe_id: paymentMethod.id,
      };

      if (!ENV_TEST) {
        const captchaToken = await recaptchaRef.current.executeAsync();
        payload.captcha_token = captchaToken;
      }

      await api.post('/payments/payment_methods', payload);
      onHide();
    } catch (error) {
      setError(PAYMENT_ERROR);
    } finally {
      setProcessingPayment(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setValidated(true);

    const form = event.currentTarget;
    const isValid = form.checkValidity();
    if (!isValid) {
      return;
    }

    Tracking.trackUserClicked({
      widget_name: 'Confirm and Pay',
    });

    if (MOCK_PAYMENT) {
      return onPaymentSuccessful();
    }

    // Stripe.js has not loaded yet.
    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement
    const cardElement = elements.getElement(CardNumberElement);

    setProcessingPayment(true);

    // Charge card on invoices (single purchase) or non-free-trial subscriptions
    const stripeCardPayment = async (secret) => {
      const { error, paymentIntent } = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: get(formData, 'name', currentUser.user_name),
            email: currentUser.email,
            address: {
              postal_code: get(formData, 'zipCode', ''),
              country: get(formData, 'country', 'US'),
            },
          },
        },
      });

      if (error) {
        console.log('[error]', error);
        setError(error.message);
        setProcessingPayment(false);

        return null;
      }

      return paymentIntent.payment_method;
    };

    if (subscriptionType) {
      /* HANDLE FREE TRIAL */
      if (selectedPlan.free_trial) {
        try {
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: get(formData, 'name', currentUser.user_name),
              email: currentUser.email,
              address: {
                postal_code: get(formData, 'zipCode', ''),
                country: get(formData, 'country', 'US'),
              },
            },
          });

          await api.post('/payments/payment_methods', {
            stripe_id: paymentMethod.id,
          });
          const trialSubscription = await createSubscription(
            selectedPlan.id,
            true,
            false,
            couponRef,
            recaptchaRef,
            setSubscriptionInfo,
            setError,
            setProcessingPayment,
          );

          trackTrialReminder(
            paymentMethod,
            trialSubscription,
            ftue,
            currentUser,
          );

          return successfulPaymentFlow(trialSubscription.stripe_id);
        } catch (error) {
          setProcessingPayment(false);
          setError(PAYMENT_ERROR);
          return;
        }
      }

      /* HANDLE NORMAL SUBSCRIPTION */
      // 1. create the subscription
      let newSubscription;
      try {
        newSubscription = await createSubscription(
          selectedPlan.id,
          false,
          false,
          couponRef,
          recaptchaRef,
          setSubscriptionInfo,
          setError,
          setProcessingPayment,
        );
      } catch (error) {
        setError(PAYMENT_ERROR);
        setProcessingPayment(false);
        return;
      }

      // 2. stripe confirms and charges the card
      let payment_method = 'pm';
      if (coupon === null || coupon.percent_off !== 100) {
        payment_method = null;
        payment_method = await stripeCardPayment(newSubscription.client_secret);
      }

      // 3. update subscription payment method
      if (payment_method) {
        try {
          const { status, stripe_id } = await updateSubscription(
            payment_method,
            currentUser,
          );

          if (status === 'active') {
            successfulPaymentFlow(stripe_id);
          } else {
            throw new Error('Invoice could not be updated or wrong status');
          }
        } catch (error) {
          setError(error.message);
          setProcessingPayment(false);
        }
      }
    } else {
      // Regular Invoice (one-time payment)

      // 1. create (post) /invoice
      let invoice;

      try {
        // Create before a Stripe payment
        invoice = await createInvoice();
      } catch (error) {
        setError(PAYMENT_ERROR);
        setProcessingPayment(false);
        return;
      }

      // 2. Stripe confirms and charges the card
      const payment_method = await stripeCardPayment(invoice.client_secret);

      // 3. Invoice was paid successfully, update (put) /invoice
      try {
        if (payment_method) {
          const { status } = await updateInvoice(invoice.id);

          if (status === 'paid') {
            successfulPaymentFlow(invoice.stripe_id);
          } else {
            throw new Error('Invoice could not be updated or wrong status');
          }
        }
      } catch {
        setError(error.message);
        setProcessingPayment(false);
      }
    }
  };

  const fetchPromoCode = async () => {
    setPromoCodeStatus('LOADING');

    try {
      const { data } = await api.get(
        `/payments/coupons/${promoCodeValue}?price_id=${selectedPlan.id}`,
      );
      setCoupon({ promoCode: promoCodeValue, ...data.coupon });

      if (paymentRequest) {
        const new_amount = Math.round(
          ((100 - data.coupon.percent_off) * selectedPlan.amount) / 100,
        );

        paymentRequest.update({
          total: {
            label: selectedPlan.subTitle,
            amount: new_amount,
          },
        });
      }

      setPromoCodeStatus('SUCCESS');
    } catch (error) {
      setPromoCodeStatus('ERROR');
    }
  };

  const handlePromoCodeInput = (event) => {
    setPromoCodeStatus('');
    setCoupon(false);
    setPromoCodeValue(event.target.value);
  };

  const visiblePlanFeatures =
    selectedPlan.id === 3 ? PLAN_FEATURES.slice(1) : PLAN_FEATURES;
  const showFreeTrialScreen = showPricingExperimentScreen && !initialScreen;

  const renderLeftScreen = () => {
    switch (currentScreen) {
      case 'second':
        return (
          <SignUpDescription
            isPYG={selectedPlan?.oneTimePayment}
            onBack={() => setCurrentScreen('first')}
          />
        );
      case 'third':
        return (
          <SelectedPlanDetails
            coupon={coupon}
            currentUser={currentUser}
            features={visiblePlanFeatures}
            selectedPlan={selectedPlan}
          />
        );
      case 'first':
      default:
        return (
          <Description
            items={productSpecs}
            currentUser={currentUser}
            onBack={() => {
              setShowPricingExperimentScreen(true);
            }}
          />
        );
    }
  };

  const renderRightScreen = () => {
    switch (currentScreen) {
      case 'second':
        return (
          <AccountCreation
            onContinue={() => setCurrentScreen('third')}
            onHide={onHide}
          />
        );
      case 'third':
        const submitFunction =
          currentUser?.payment_method_brand && currentUser?.subscribed
            ? hanldeUpdatePayment
            : handleSubmit;
        return (
          <Payment
            currentUser={currentUser}
            error={error}
            fetchPromoCode={fetchPromoCode}
            formData={formData}
            processingPayment={processingPayment}
            promoCodeStatus={promoCodeStatus}
            promoCodeValue={promoCodeValue}
            purchaseSuccess={purchaseSuccess}
            recaptchaRef={recaptchaRef}
            reCaptchaSiteKey={reCaptchaSiteKey}
            selectedPlan={selectedPlan}
            validated={validated}
            handlePromoCodeInput={handlePromoCodeInput}
            handleSubmit={submitFunction}
            paymentRequest={paymentRequest}
            onChange={handleFormChange}
            onHide={onHide}
            onBack={() => {
              currentUser?.registered
                ? setCurrentScreen('first')
                : setCurrentScreen('second');
            }}
            showPromoCode={showPromoCode}
          />
        );
      case 'first':
      default:
        return (
          <PlanSelection
            currentUser={currentUser}
            plans={PLANS_TO_USE}
            selectedPlan={selectedPlan}
            onContinue={() => {
              Tracking.trackUserClicked(
                {
                  widget_name: 'Pricing',
                  tier: selectedPlan.statName,
                  from_view: 'Plans modal',
                },
                currentUser,
              );
              currentUser?.registered
                ? setCurrentScreen('third')
                : setCurrentScreen('second');
            }}
            onHide={onHide}
            onSelect={onSelectPlan}
          />
        );
    }
  };

  return (
    <>
      <div className="__paywall-v2" data-cy="paywall-v2">
        {purchaseSuccess && (
          <SuccessfulPurchase
            selectedPlan={selectedPlan}
            subscription={subscriptionInfo}
            subscriptionType={subscriptionType}
            email={currentUser.email}
            onHide={onHide}
          />
        )}
        {!purchaseSuccess && !showFreeTrialScreen && (
          <>
            <div
              className={classNames('left', {
                'left-variant': !showPaywallMonthDiscount,
              })}
            >
              {renderLeftScreen()}
            </div>
            <div className="right">{renderRightScreen()}</div>
          </>
        )}
        {!purchaseSuccess && showFreeTrialScreen && (
          <FreeTrialScreen
            onHide={onHide}
            selectedPlan={selectedPlan}
            setCurrentScreen={setCurrentScreen}
            onSelectPlan={onSelectPlan}
            setShowPricingExperimentScreen={setShowPricingExperimentScreen}
          />
        )}
      </div>

      {error && (
        <KoiToast
          type="danger"
          show
          message={error}
          onClose={() => setError(null)}
        />
      )}
    </>
  );
};
export default PaywallV2;
