import { ReactComponent as AssessmentIcon } from '../../assets/icons/koi/assessment-icon.svg';
import { ReactComponent as InDemandIcon } from '../../assets/icons/koi/in-demand-skills-icon.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/icons/koi/optimized-templates-icon.svg';
import { ReactComponent as UnlimitedIcon } from '../../assets/icons/koi/unlimited-icon.svg';
import Tracking from '../../utils/tracking';
import { formatCurrency, formatFromIso } from '../../utils/util';

import {
  PLANS_PAYWALL_MARKETING_CAMPAIGN,
  PLANS_PRICING_EXPERIMENT,
  PLANS_PRICING_EXPERIMENT_UNSUBSCRIBED,
  PLANS_UNSUSCRIBED_DISCOUNTS,
} from './constants';

export const DEFAULT_TARGET_PLAN = 2;

export const INITIAL_DATA = {
  name: '',
  country: 'US',
  zipCode: '',
};

export const getCurrentPlansList = ({
  usePaywallMarketingCampaign,
  isUnsubscribed,
}) => {
  if (usePaywallMarketingCampaign) {
    return PLANS_PAYWALL_MARKETING_CAMPAIGN;
  }

  return isUnsubscribed
    ? PLANS_PRICING_EXPERIMENT_UNSUBSCRIBED
    : PLANS_PRICING_EXPERIMENT;
};

export const getPlan = (currentUser, PLANS_TO_USE, target) => {
  if (currentUser.subscribed) {
    return PLANS_TO_USE.find(
      (plan) => plan.id === currentUser?.subscription?.price_id,
    );
  }
  return PLANS_TO_USE.find((plan) => plan.internal_id === target);
};

export const getPlansDiscountModalCheck = (
  isUnsubscribed,
  showPaywallDiscountModal,
  showPaywallMonthDiscount,
  utmDiscount,
  PLANS_TO_USE,
  isSubscribed,
) => {
  if (isUnsubscribed && showPaywallDiscountModal) {
    PLANS_TO_USE = PLANS_UNSUSCRIBED_DISCOUNTS.map((plan) => ({
      ...plan,
      tag: `${utmDiscount}% OFF`,
    }));
  }

  if (!isSubscribed && showPaywallMonthDiscount) {
    PLANS_TO_USE = PLANS_UNSUSCRIBED_DISCOUNTS;
  }
  return PLANS_TO_USE;
};

export const getProductSpecs = () => {
  return [
    {
      icon: <UnlimitedIcon />,
      label: 'Personalized resumes and cover letters',
    },
    {
      icon: <AssessmentIcon />,
      label: 'Psychometric assessment',
    },
    {
      icon: <InDemandIcon />,
      label: 'In-demand skills recommendations',
    },
    {
      icon: <TemplatesIcon />,
      label: 'ATS-optimized templates',
    },
  ];
};

export const trackTrialReminder = (
  paymentMethod,
  trialSub,
  ftue,
  currentUser,
) => {
  if (ftue) {
    const interval = `${trialSub.price.interval_count} ${trialSub.price.interval}${trialSub.price.interval_count > 1 ? 's' : ''}`;
    const properties = {
      card_brand: paymentMethod.card.brand,
      card_last4: paymentMethod.card.last4,
      product: trialSub.product.name,
      trial_end: formatFromIso(trialSub.trial_end),
      charged: formatCurrency(trialSub.price.unit_amount_decimal / 100),
      cutoff: formatFromIso(trialSub.cutoff),
      interval,
      first_cta: `${process.env.REACT_APP_BASE_URL}/payments`,
      second_cta: `${process.env.REACT_APP_BASE_URL}/subscription`,
    };

    const email = currentUser?.valid_email;
    if (email) {
      Tracking.trackBrevoEvent(email, 'ftue_trial', properties, {});
    }
  }
};
