import { useContext } from 'react';
import classNames from 'classnames';
import { NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as AccountIcon } from '../../assets/icons/koi/account-icon.svg';
import AppContext from '../../store/AppContext';

import './AccountMenu.scss';

export default function AccountMenu() {
  const location = useLocation();
  const [state] = useContext(AppContext);
  const { currentUser } = state;

  return (
    <NavDropdown className="__account-menu" title={<AccountIcon />}>
      {currentUser?.onboarding_step === 'dashboard' && (
        <>
          <NavDropdown.Item
            as={Link}
            to="/account"
            className={classNames({
              active: location.pathname.includes('/settings'),
            })}
          >
            Account settings
          </NavDropdown.Item>

          {currentUser?.referrer_count < 3 && (
            <NavDropdown.Item
              as={Link}
              to="/invite"
              className={classNames({
                active: location.pathname.includes('/invite'),
              })}
            >
              Invite Friends
            </NavDropdown.Item>
          )}
        </>
      )}

      <NavDropdown.Item as={Link} to="/logout">
        Log out
      </NavDropdown.Item>
    </NavDropdown>
  );
}
