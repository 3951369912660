import { Link } from 'react-router-dom';

import KoiButton from '../../common/KoiButton/KoiButton';
import TLink from '../../layout/TLink';
import { staticPage } from '../../utils/util';

import AccountMenu from './AccountMenu';
import TrudyLogo from './TrudyLogo';

import './DesktopHeader.scss';

export default function DesktopHeader({
  children,
  currentUser,
  superUser,
  checkPagePath = {},
  onForceLogin,
}) {
  const {
    isFTUEPreviewPage,
    isProfileBuilderPage,
    isPublicProfilePage,
    isResumeBuilderPage,
  } = checkPagePath;

  const renderPublicMenu = () => {
    if (isPublicProfilePage) {
      return (
        <>
          <a
            className="nav-item"
            href={staticPage('/truprofile')}
            target="_blank"
            rel="noreferrer"
          >
            TruProfile
          </a>
          <a className="nav-item" href="/login">
            Log In
          </a>
          <KoiButton
            className="cta-button"
            variant="primary"
            as={Link}
            to={'/profile-builder'}
          >
            Try it now
          </KoiButton>
        </>
      );
    }

    if (isResumeBuilderPage || isProfileBuilderPage) {
      return (
        <div className="header-button">
          <p>Already have an account?</p>
          <KoiButton
            variant="secondary"
            className="login-btn"
            onClick={onForceLogin}
          >
            Log In
          </KoiButton>
        </div>
      );
    }

    return (
      <>
        {!isFTUEPreviewPage && (
          <a
            className="nav-item"
            href={staticPage('/truprofile')}
            target="_blank"
            rel="noreferrer"
          >
            TruProfile <span className="new-badge">NEW</span>
          </a>
        )}

        {!isFTUEPreviewPage && (
          <>
            <a
              className="nav-item"
              href={staticPage('/resume')}
              target="_blank"
              rel="noreferrer"
            >
              Resume
            </a>
            <a
              className="nav-item"
              href={staticPage('/pricing')}
              target="_blank"
              rel="noreferrer"
            >
              Pricing
            </a>
            <a className="nav-item" href={'/login'}>
              Log In
            </a>
            <KoiButton
              className="cta-button"
              variant="primary"
              as={Link}
              to={'/resume-builder'}
            >
              Try it now
            </KoiButton>
          </>
        )}
      </>
    );
  };

  const renderPrivateMenu = () => {
    if (superUser) {
      return (
        <TLink
          className="nav-item"
          to="/logout"
          properties={{ widget_name: 'Log Out' }}
        >
          Log out
        </TLink>
      );
    }

    return (
      <>
        <TLink
          className="nav-item"
          data-cy="pricing-link"
          to="/plans"
          properties={{ widget_name: 'Pricing' }}
        >
          Pricing
        </TLink>

        {currentUser.onboarding_step === 'dashboard' && (
          <TLink
            className="nav-item"
            data-cy="dashboard-link"
            to="/dashboard"
            properties={{ widget_name: 'Dashboard' }}
          >
            My dashboard
          </TLink>
        )}

        <AccountMenu />
      </>
    );
  };

  return (
    <div className="__desktop-header">
      <div className="flex">
        <TrudyLogo />
        {superUser && <p className="admin-label">ADMIN</p>}
      </div>

      {children}

      <div className="menu-links">
        {!currentUser?.registered ? renderPublicMenu() : renderPrivateMenu()}
      </div>
    </div>
  );
}
