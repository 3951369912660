import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import queryClient from '../api/queryClient';
import ContextProviders from '../store/ContextProviders';
import { getEnvSetting } from '../utils/envSettings';
import { ErrorBoundary } from './BugsnagProvider';
import LaunchDarklyProvider from './LaunchDarklyProvider';

const googleClientId = getEnvSetting('GOOGLE_CLIENT_ID');

const stripePublicKey = getEnvSetting('STRIPE_PUBLIC_KEY');
const stripe = loadStripe(stripePublicKey);
const stripeOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400&display=swap',
    },
  ],
};

export default function AppProviders({ children }) {
  return (
    <ErrorBoundary>
      <LaunchDarklyProvider>
        <GoogleOAuthProvider clientId={googleClientId}>
          <BrowserRouter>
            <Elements stripe={stripe} options={stripeOptions}>
              <ContextProviders>
                <QueryClientProvider client={queryClient}>
                  {children}
                </QueryClientProvider>
              </ContextProviders>
            </Elements>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </LaunchDarklyProvider>
    </ErrorBoundary>
  );
}
