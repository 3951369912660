import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../store/AppContext';
import { useDocumentBuilderContext } from '../store/DocumentBuilderContext';

export default function useStartDocumentBuilder() {
  const { appDispatch } = useAppContext();
  const { dbDispatch } = useDocumentBuilderContext();

  const navigate = useNavigate();

  function startDocumentBuilder() {
    dbDispatch({ type: 'documentBuilder/reset' });
    appDispatch({ type: 'currentResume/set', payload: null });
    appDispatch({ type: 'currentCoverLetter/set', payload: null });

    navigate('/document-builder');
  }

  return startDocumentBuilder;
}
