export const CONTROL_VARIANT = 'Control';

export const FeatureFlags = {
  PRICING_EXPERIMENT: 'experiment-3-month-pricing-package',
  TRUSTPILOT: 'trustpilot-feature-flag',
  PAY_AS_YOU_GO_PAYWALL_MARKETING_CAMPAIGN:
    'pay-as-you-go-paywall-marketing-campaign',
  CHAINED_PROMPT_BUILDER_UPDATES: 'chained-prompt-builder-updates',
  UPDATE_CHECKOUT: 'blocked-payment-reduction-update-checkout',
  GOOGLE_SIGNUP: 'google-sso-sign-up',
  GOOGLE_LOGIN: 'google-sso-login',
  SECTION_REORDER: 'section-reorder',
  RESUME_PROMPT_ROLLOUT: 'resume-prompt-rollout',
};

export const CustomConversionEvents = {
  CREATE_NEW_ACCOUNT: 'create-new-account',
  RESUME_BUILDER_NEXT_STEP: 'resume-builder-next-step',
  COMPLETE_PURCHASE: 'complete-purchase',
  IMPORT_FROM_LINKED_IN_SELECTED: 'import-from-linked-in-selected',
  IMPORT_FROM_FILE_SELECTED: 'import-from-file-selected',
  MANUAL_ENTRY_SELECTED: 'manual-entry-selected',
  GENERATE_FIRST_RESUME: 'generate-first-resume',
};
