import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Tracking from '../utils/tracking';

// Custom hook that tracks pageviews on all different trackers when the location path changes:
export default function useTracking() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');

  // if the UTM Source is set once, save it for that device/browser:
  useEffect(() => {
    utmSource && localStorage.setItem('__msc_utm_source', utmSource);
  }, [utmSource]);

  useEffect(() => {
    const params = [...searchParams.entries()].reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    const defaultTrackingObject = {
      page_name: location.pathname,
    };
    Tracking.trackPageViewed(
      location.search
        ? { ...defaultTrackingObject, ...params }
        : defaultTrackingObject,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
