import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateLayout from '../layout/PrivateLayout';
import PublicLayout from '../layout/PublicLayout';
import ErrorBoundary from './ErrorBoundary';
import KoiLandingPage from './LandingPage/KoiLandingPage';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const AccountSection = lazy(
  () => import('./Account/AccountSection/AccountSection'),
);
const AssessmentContentV2 = lazy(() => import('./Results/AssessmentContentV2'));
const MembershipSection = lazy(
  () => import('./Account/MembershipSection/MembershipSection'),
);
const PaymentSection = lazy(
  () => import('./Account/PaymentSection/PaymentSection'),
);
const FeedbackSection = lazy(
  () => import('./Account/FeedbackSection/FeedbackSection'),
);
const EmailVerification = lazy(() => import('./Auth/EmailVerification'));
const LoginPage = lazy(() => import('./Auth/LoginPage'));
const Logout = lazy(() => import('./Auth/Logout'));
const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
const SignUpPage = lazy(() => import('./Auth/SignUpPage'));
const ResumeDownload = lazy(() => import('./Builder/ResumeDownload'));
const PaymentPlans = lazy(() => import('./Checkout/PaymentPlans'));
const InvitePage = lazy(() => import('./Invite/InvitePage'));
const DoInvite = lazy(() => import('./Invite/DoInvite'));
const DashboardV2 = lazy(() => import('./Dashboard/V2/DashboardV2'));
const Design = lazy(() => import('./Design/Design'));
const DocumentBuilderContent = lazy(
  () => import('./DocumentBuilderPage/DocumentBuilderContent'),
);
const EditCoverLetterPage = lazy(
  () => import('./EditCoverLetterPage/EditCoverLetterPage'),
);
const EditCoverLetterTemplatePage = lazy(
  () => import('./EditCoverLetterTemplatePage/EditCoverLetterTemplatePage'),
);
const EditResumePage = lazy(() => import('./EditResumePage/EditResumePage'));
const EditResumeTemplatePage = lazy(
  () => import('./EditResumeTemplatePage/EditResumeTemplatePage'),
);
const EditResumeSkillsPage = lazy(
  () => import('./EditResumeSkillsPage/EditResumeSkillsPage'),
);
const StaticPlaceholder = lazy(() => import('./LandingPage/StaticPlaceholder'));
const AssessmentResults = lazy(() => import('./Results/AssessmentResults'));

const ResumeBuilderPage = lazy(
  () => import('./ResumeBuilderPage/ResumeBuilderPage'),
);
const SubscriptionCanceledPage = lazy(
  () => import('./SubscriptionCanceledPage/SubscriptionCanceledPage'),
);
const TakeTest = lazy(() => import('./TakeTest/TakeTest'));
const AdminPortal = lazy(() => import('./AdminPortal/AdminPortal'));
const ProfilePage = lazy(() => import('./ProfilePage/ProfilePage'));
const EditProfileHeaderPage = lazy(
  () => import('./EditProfileHeaderPage/EditProfileHeaderPage'),
);
const EditProfileHeaderSkillsPage = lazy(
  () => import('./EditProfileHeaderSkillsPage/EditProfileHeaderSkillsPage'),
);
const EditProfileExperienceSkillsPage = lazy(
  () =>
    import('./EditProfileExperienceSkillsPage/EditProfileExperienceSkillsPage'),
);
const ProfileSkillsPage = lazy(
  () => import('./ProfileSkillsPage/ProfileSkillsPage'),
);
const ProfileEducationPage = lazy(
  () => import('./ProfileEducationPage/ProfileEducationPage'),
);
const ProfileHobbyPage = lazy(
  () => import('./ProfileHobbyPage/ProfileHobbyPage'),
);
const ProfileLicensePage = lazy(
  () => import('./ProfileLicensePage/ProfileLicensePage'),
);
const ProfileAwardPage = lazy(
  () => import('./ProfileAwardPage/ProfileAwardPage'),
);
const ProfileProjectPage = lazy(
  () => import('./ProfileProjectPage/ProfileProjectPage'),
);
const ProfileLanguagePage = lazy(
  () => import('./ProfileLanguagePage/ProfileLanguagePage'),
);
const ProfileAssociationPage = lazy(
  () => import('./ProfileAssociationPage/ProfileAssociationPage'),
);
const ProfilePatentPage = lazy(
  () => import('./ProfilePatentPage/ProfilePatentPage'),
);
const ProfilePublicationPage = lazy(
  () => import('./ProfilePublicationPage/ProfilePublicationPage'),
);
const ProfileExperiencePage = lazy(
  () => import('./ProfileExperiencePage/ProfileExperiencePage'),
);
const ProfileVolunteerPage = lazy(
  () => import('./ProfileVolunteerPage/ProfileVolunteerPage'),
);
const ProfileAboutPage = lazy(
  () => import('./ProfileAboutPage/ProfileAboutPage'),
);

export default function AppRoutes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<PublicRoutes onlyGuests />}>
            <Route path="/login" element={<LoginPage />} exact />
            <Route path="/static" element={<StaticPlaceholder />} exact />
          </Route>

          <Route element={<PublicRoutes />}>
            <Route path="/assessment" element={<TakeTest />} exact />
            <Route path="/sign-up" element={<SignUpPage />} exact />
            <Route path="/design" element={<Design />} exact />
            <Route path="/password-reset" element={<ResetPassword />} exact />
            <Route
              path="/invite/:referrer_invite_link"
              element={<DoInvite />}
              exact
            />
            <Route
              path="/profile/:hash"
              element={<AssessmentResults publicPage />}
              exact
            />
            <Route
              path="/resume-builder"
              element={<ResumeBuilderPage />}
              exact
            />
            <Route
              path="/profile-builder"
              element={<ResumeBuilderPage />}
              exact
            />
          </Route>

          <Route element={<PublicLayout />}>
            <Route
              path="/u/:vanityUrl"
              element={<ProfilePage publicProfile />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<PrivateLayout />}>
              <Route path="/dashboard" element={<DashboardV2 />} exact />
              <Route path="/profile" element={<ProfilePage />} exact />
              <Route
                path="/profile/edit-header"
                element={<EditProfileHeaderPage />}
                exact
              />
              <Route
                path="/profile/edit-header/skills"
                element={<EditProfileHeaderSkillsPage />}
                exact
              />
              <Route
                path="/profile/skills"
                element={<ProfileSkillsPage />}
                exact
              />
              <Route
                path="/profile/educations/new"
                element={<ProfileEducationPage />}
                exact
              />
              <Route
                path="/profile/educations/:educationId"
                element={<ProfileEducationPage />}
                exact
              />
              <Route
                path="/profile/hobbies/new"
                element={<ProfileHobbyPage />}
                exact
              />
              <Route
                path="/profile/hobbies/:hobbyId"
                element={<ProfileHobbyPage />}
                exact
              />
              <Route
                path="/profile/licenses/new"
                element={<ProfileLicensePage />}
                exact
              />
              <Route
                path="/profile/licenses/:licenseId"
                element={<ProfileLicensePage />}
                exact
              />
              <Route
                path="/profile/awards/new"
                element={<ProfileAwardPage />}
                exact
              />
              <Route
                path="/profile/awards/:awardId"
                element={<ProfileAwardPage />}
                exact
              />
              <Route
                path="/profile/languages/new"
                element={<ProfileLanguagePage />}
                exact
              />
              <Route
                path="/profile/languages/:languageId"
                element={<ProfileLanguagePage />}
                exact
              />

              <Route
                path="/profile/associations/new"
                element={<ProfileAssociationPage />}
                exact
              />
              <Route
                path="/profile/associations/:associationId"
                element={<ProfileAssociationPage />}
                exact
              />
              <Route
                path="/profile/projects/new"
                element={<ProfileProjectPage />}
                exact
              />
              <Route
                path="/profile/projects/:projectId"
                element={<ProfileProjectPage />}
                exact
              />

              <Route
                path="/profile/patents/new"
                element={<ProfilePatentPage />}
                exact
              />
              <Route
                path="/profile/patents/:patentId"
                element={<ProfilePatentPage />}
                exact
              />
              <Route
                path="/profile/publications/new"
                element={<ProfilePublicationPage />}
                exact
              />
              <Route
                path="/profile/publications/:publicationId"
                element={<ProfilePublicationPage />}
                exact
              />
              <Route
                path="/profile/experiences/new"
                element={<ProfileExperiencePage />}
                exact
              />
              <Route
                path="/profile/experiences/:experienceId"
                element={<ProfileExperiencePage />}
                exact
              />
              <Route
                path="/profile/experiences/:experienceId/skills"
                element={<EditProfileExperienceSkillsPage />}
                exact
              />
              <Route
                path="/profile/volunteers/new"
                element={<ProfileVolunteerPage />}
                exact
              />
              <Route
                path="/profile/volunteers/:volunteerExperienceId"
                element={<ProfileVolunteerPage />}
                exact
              />
              <Route
                path="/profile/about"
                element={<ProfileAboutPage />}
                exact
              />
              <Route
                path="/assessment-results"
                element={<AssessmentContentV2 fromDashboard />}
                exact
              />
              <Route
                path="/document-builder"
                element={<DocumentBuilderContent />}
                exact
              />
              <Route path="/feedback" element={<FeedbackSection />} exact />

              <Route path="/account" element={<AccountSection />} exact />
              <Route
                path="/subscription"
                element={<MembershipSection />}
                exact
              />
              <Route path="/payments" element={<PaymentSection />} exact />
            </Route>

            <Route path="/plans" element={<PaymentPlans />} exact />
            <Route path="/invite" element={<InvitePage />} exact />
            <Route path="/results" element={<AssessmentResults />} exact />
            <Route path="/verify" element={<EmailVerification />} exact />
            <Route path="/view-resume/:id" element={<ResumeDownload />} exact />
            <Route path="/edit-resume/:id" element={<EditResumePage />} exact />
            <Route
              path="/edit-resume/:id/template"
              element={<EditResumeTemplatePage />}
              exact
            />
            <Route
              path="edit-resume/:id/skills"
              element={<EditResumeSkillsPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id"
              element={<EditCoverLetterPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id/template"
              element={<EditCoverLetterTemplatePage />}
              exact
            />
            <Route
              path="/subscription-canceled"
              element={<SubscriptionCanceledPage />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes superUser />}>
            <Route path="/admin" element={<AdminPortal />} exact />
          </Route>

          <Route path="/logout" element={<Logout />} exact />

          <Route path="*" element={<KoiLandingPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
