import { PAYMENT_ERROR } from '../../constants/payment';
import { api } from '../../utils/api';
import { ENV_TEST } from '../../utils/envSettings';

export const updateSubscription = async (payment_method, currentUser) => {
  // Updated after a valid Stripe card_payment
  const payload = { subscription: { payment_method } };
  const response = await api.put(
    `/payments/subscriptions/${currentUser.id}`,
    payload,
  );
  return response.data;
};

export const updateInvoice = async (invoice_id) => {
  const { data } = await api.put(`/payments/invoices/${invoice_id}`);
  return data;
};

export const createInvoice = async (
  charge_automatically = false,
  selectedPlan,
  couponRef,
) => {
  const invoice = {
    invoice: {
      price_id: selectedPlan.id,
      coupon: couponRef.current?.id,
      charge_automatically,
    },
  };

  const response = await api.post('/payments/invoices', invoice);
  return response.data;
};

export const createSubscription = async (
  price_id,
  trial = false,
  charge_automatically = false,
  couponRef,
  recaptchaRef,
  setSubscriptionInfo,
  setError,
  setProcessingPayment,
) => {
  const payload = {
    subscription: {
      price_id,
      trial,
      coupon: couponRef.current?.id,
      charge_automatically,
    },
  };

  if (!ENV_TEST) {
    const captchaToken = await recaptchaRef.current.executeAsync();
    // recaptchaRef.current.reset();
    payload.captcha_token = captchaToken;
  } else {
    payload.subscription.mocked = true;
  }

  try {
    const response = await api.post('/payments/subscriptions/', payload);
    setSubscriptionInfo(response.data);
    return response.data;
  } catch (error) {
    setError(PAYMENT_ERROR);
    setProcessingPayment(false);
  }
};
