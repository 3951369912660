import { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from './constants/featureFlags';
import useFeatureFlag from './hooks/useFeatureFlag';
import useTracking from './hooks/useTracking';
import AppRoutes from './routes/AppRoutes';
import AppContext from './store/AppContext';
import { api } from './utils/api';
import Tracking from './utils/tracking';
import { getUserExperimentProperties, setUserForProviders } from './utils/user';

import './modals/Modal.scss';

Tracking.initialize();

export default function App() {
  const [state, dispatch] = useContext(AppContext);
  const [notIdentifiedUser, setNotIdentifiedUser] = useState(false);

  const ldClient = useLDClient();
  const UPDATE_CHECKOUT_ENABLED = useFeatureFlag(
    FeatureFlags.UPDATE_CHECKOUT,
    'Control',
  );
  const { currentUser } = state;
  useTracking();

  useEffect(() => {
    if (notIdentifiedUser && currentUser?.id) {
      logExperimentStartedEvents(currentUser);
      setNotIdentifiedUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Fetch logged in user if access token cookie is present
  useEffect(() => {
    const trackReferralCompletion = async (user) => {
      if (user?.referrer_count > 2 && !user?.referrals_completion) {
        const { data } = await api.put(`/users/${user.id}`, {
          user: { referrals_completion: true },
        });
        dispatch({ type: 'currentUser/set', payload: data });

        Tracking.trackConditionMet({
          widget_name: 'Unlock Free Month',
          context: 'Referrals',
        });
      }
    };

    const getLoggedInUser = async () => {
      try {
        dispatch({ type: 'loadingLoggedInUser/set', payload: true });

        const { data } = await api.get('/users/me');
        dispatch({ type: 'currentUser/set', payload: data });
        await trackReferralCompletion(data);
        await setUserForProviders(data, ldClient, false, 'App');
        dispatch({ type: 'loadingLoggedInUser/set', payload: false });
        logExperimentStartedEvents(data);
      } catch {
        dispatch({ type: 'loggedIn/set', payload: false });
        dispatch({ type: 'loadingLoggedInUser/set', payload: false });
      }
    };

    if (!Cookies.get('talent__api_access_token')) {
      dispatch({ type: 'loggedIn/set', payload: false });
      logExperimentStartedEvents();
      setNotIdentifiedUser(true);
    } else {
      dispatch({ type: 'loggedIn/set', payload: true });
      getLoggedInUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ldClient]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const logExperimentStartedEvents = async (currentUser) => {
    const userProperties = getUserExperimentProperties(currentUser);
    //Just for safety with no impact in user experience, Ensure flags are correctly fetched
    await sleep(2000);

    if (userProperties.userIdentified) {
      // track experiments that should only send logged-in user data
    }
    Tracking.trackLDExperiment({
      'Experiment name': FeatureFlags.UPDATE_CHECKOUT,
      'Variant name': UPDATE_CHECKOUT_ENABLED,
      userProperties,
    });
  };

  return (
    <>
      <AppRoutes />
    </>
  );
}
